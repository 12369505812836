#hex-paper-mill,
#hex-ceramic-tile {
  background: linear-gradient(180deg, #3a65a8 0%, #42917c 100%);
}

#hex-sugar-mill,
#hex-sugar-refinery-mill,
#hex-detergent {
  background: linear-gradient(180deg, #42917c 0%, #3a65a8 100%);
}

// #hex-sugar-refinery-mill {
//   background: #3a65a8;
// }

// #hex-detergent {
//   background: #42917c;
// }

// .list-hex-grid {
//   margin-top: toRem(55);
//   margin-bottom: toRem(55);
//   word-spacing: 0;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;

//   .grid {
//     margin-left: 10px;
//     margin-right: 10px;
//     // max-width: 100%;
//     // width: 190px;
//   }

//   .grid-3 {
//     width: toRem(190);
//   }

//   div.hex {
//     display: inline-block;
//     vertical-align: middle;
//     position: relative;
//     float: none;
//     z-index: 20;
//     margin-top: 37px;
//     margin-bottom: 37px;
//     width: 210px;

//     .hex-title {
//       position: absolute;
//       z-index: 25;
//       top: 40%;
//       width: 100%;
//       text-align: center;
//       color: white;
//       font-size: 22px;
//     }

//     .hex-contents {
//       background-color: #ee6557;
//       font-size: 18px;
//       word-spacing: 1px;
//       text-align: center;
//       border: none;
//       color: #fff;
//       display: block;
//       transform: rotate(60deg);
//     }

//     & > .hex-contents {
//       height: 110px;
//       line-height: 220px;
//     }

//     & div.hex-1 {
//       width: 100%;
//       height: 100%;
//       transform: rotate(60deg);
//       overflow: hidden;
//       background: inherit;
//       border: inherit;
//       z-index: 2;
//       top: 0;
//       left: 0;
//       position: absolute;
//       border-bottom: 1px solid rgba(187, 187, 187, 1);
//       border-top: 1px solid rgba(187, 187, 187, 1);
//     }

//     & div.hex-2 {
//       width: 100%;
//       height: 100%;
//       transform: rotate(-60deg);
//       overflow: hidden;
//       background: inherit;
//       border: inherit;
//       z-index: 2;
//       position: absolute;
//     }
//   }
// }

$hexHeight: toRem(220);
$hexWidth: toRem(220);

.list-hex-grid {
  margin-top: toRem(60);
  margin-bottom: toRem(60);
  padding-bottom: toRem(60);
  font-size: 0;
  word-spacing: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .grid {
    margin-left: toRem(20);
    margin-right: toRem(20);
    max-width: 100%;
  }

  @include responsive(tab-port) {
    .first-grid,
    .second-grid {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  div.hex {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    float: none;
    z-index: 20;
    margin-top: toRem(40);
    margin-bottom: toRem(40);
    width: toRem(190);

    @include responsive(tab-port) {
      padding-top: toRem(25);
      padding-bottom: toRem(25);
    }

    .hex-title {
      position: absolute;
      z-index: 23;
      top: 35%;
      width: 100%;
      text-align: center;
      color: white;
      font-size: toRem(24);
    }

    .hex-contents {
      background-color: #ee6557;
      font-size: toRem(18);
      word-spacing: 1px;
      text-align: center;
      border: none;
      color: #fff;
      display: block;
      transform: rotate(60deg);
    }

    div.hex-1 {
      width: 100%;
      height: 100%;
      transform: rotate(60deg);
      overflow: hidden;
      background: inherit;
      border: inherit;
      z-index: 2;
      top: 0;
      left: 0;
      position: absolute;
      border-bottom: 1px solid rgba(187, 187, 187, 1);
      border-top: 1px solid rgba(187, 187, 187, 1);
    }

    div.hex-2 {
      width: 100%;
      height: 100%;
      transform: rotate(-60deg);
      overflow: hidden;
      background: inherit;
      border: inherit;
      z-index: 2;
      position: absolute;
    }
  }
}

.list-hex-grid div.hex > .hex-contents {
  height: toRem(110);
  line-height: toRem(220);
}

.list-hex-grid div.hex .hex-1:before,
.list-hex-grid div.hex .hex-2:before {
  content: "";
  position: absolute;
  background: inherit;
  border: inherit;
  left: 0;
  height: $hexHeight;
  width: $hexWidth;
}

.list-hex-grid div.hex .hex-2:before {
  bottom: 0;
  transform: rotate(60deg) translate(40px, 40px);
}

.list-hex-grid div.hex .hex-1:before {
  bottom: 0;
  transform: rotate(-60deg) translate(-70px, 0);
}
