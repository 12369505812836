.carousel {
  margin-top: toRem(-90);
  z-index: -10;

  @include responsive(tab-port) {
    margin-top: toRem(-100);
  }
}

.hero-margin {
  margin-top: toRem(-90);
  z-index: -10;

  @include responsive(tab-port) {
    margin-top: toRem(-100);
  }
}

.carousel-control.left {
  z-index: 10;
  left: 0;
}

.carousel-control.right {
  z-index: 10;
  right: 0;
}

.btn-border-50 {
  border-radius: toRem(50);
  border-width: 2px;
}

.btn-border-width-2 {
  border-width: toRem(2);
}

.btn-home {
  padding: 0.8rem 2rem;
  font-weight: 700;
  color: black;
  background-color: transparent;
  border-color: rgb(51, 51, 51);
  &:hover {
    color: #0183c5;
    border-color: #0183c5;
    background-color: #fff;
  }

  @include responsive(big-desktop) {
    font-size: 125%;
  }

  @include responsive(tab-port) {
    font-size: 130%;
  }
}

.about-us {
  display: flex;
  justify-content: space-around;
  padding: 6rem 4rem;
  align-items: center;

  @include responsive(tab-port) {
    flex-direction: column;
    justify-content: center;
  }

  &__left {
    margin-right: toRem(40);
    img {
      height: toRem(461);
    }

    @include responsive(tab-port) {
      margin-right: 0px;
      img {
        height: auto;
        width: 100%;
      }
    }
  }

  &__right {
    padding: 2rem;
    margin-left: toRem(40);

    @include responsive(tab-port) {
      padding: 0px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
    }
  }

  h1 {
    font-size: toRem(42);
    font-weight: 600;
  }

  p {
    font-size: toRem(20);
    max-width: toRem(540);
  }

  &__content {
    padding-bottom: toRem(30);
    @include responsive(tab-port) {
      text-align: center;
    }
  }
}

.product-service {
  margin-bottom: toRem(90);

  p {
    text-align: center;
    font-size: toRem(20);
  }

  &__container {
    background-image: url("/assets/img/home/product_service_bg.png");
    height: toRem(390);
    color: white;
    text-align: center;
  }

  &__sub-title {
    padding-top: toRem(100);
  }

  &__title {
    font-size: 48px;
  }

  &__application {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: toRem(-100);
    margin-bottom: toRem(60);

    @include responsive(tab-port) {
      flex-direction: column;
      align-items: center;
      padding-top: 3rem;
    }

    &__container {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      cursor: pointer;
    }

    &__container:first-child {
      margin-right: toRem(60);
      @include responsive(tab-port) {
        margin-right: 0px;
        margin-bottom: toRem(60);
      }
    }

    &__container:last-child {
      margin-left: toRem(60);
      @include responsive(tab-port) {
        margin-left: 0px;
        margin-top: toRem(60);
      }
    }

    &__application,
    &__image {
      position: relative;
      display: inline-block;
    }
    &__image {
      img {
        @include responsive(tab-port) {
          width: 95%;
        }
      }
    }
    &__image:hover:after {
      content: "";
      position: absolute;
      left: 4px;
      top: 4px;
      border-radius: 25px;
      width: 262px;
      height: 262px;
      display: inline-block;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(1, 131, 197, 0.25) 100%
      );
    }

    &__title {
      font-weight: 800;
      text-transform: uppercase;
      padding: 1.2rem 0;
      padding-bottom: 1rem;
      line-height: 1.53rem;
      // border: 2px solid #726d6d;
      border-radius: 20px;
      width: 14.5rem;
      margin: 0 auto;
      font-size: 20px;
      margin-top: -16px;
      text-align: center;
      z-index: -2;
      max-width: 232px;

      // color: #726d6d;
    }

    // &__title:hover {
    //   border: 2px solid #0183c5;
    // color: #0183c5;
    // }
  }
}

.contact-us {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f3f5fa;

  @include responsive(tab-port) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__right {
    // font-weight: 600;
    flex-basis: 35%;
    margin-right: toRem(40);
    margin-left: toRem(20);
    margin-bottom: toRem(40);

    p {
      padding-top: toRem(25);
      font-size: toRem(20);
    }

    @include responsive(tab-port) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        text-align: center;
        width: 85%;
        font-size: toRem(22);
      }
    }
  }
}

.stats {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-image: url("/assets/img/home/statistics_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: toRem(290);

  @include responsive(tab-port) {
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
    height: auto;
    min-height: toRem(290);
  }

  &__divider {
    height: calc(100% - 9rem);
    width: 2px;
    background-color: white;

    @include responsive(tab-port) {
      width: calc(100% - 19rem);
      height: 1px;
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 50px;
    }
  }

  &__cards {
    color: white;
    font-weight: 700;
    text-align: center;
    padding: toRem(20);
    @include responsive(tab-port) {
      padding: 0;
    }
    h1 {
      font-size: toRem(60);
      font-weight: 700;

      @include responsive(tab-port) {
        font-size: toRem(40);
        font-weight: 600;
      }
    }
    p {
      font-size: toRem(16);
    }
  }
}

.article {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: toRem(60);
    padding-bottom: toRem(100);
  }

  &__contents {
    display: flex;
    flex-direction: row;

    @include responsive(tab-port) {
      flex-direction: column;
    }
  }

  &__title {
    color: #11623d;
    font-size: toRem(48);
    span {
      font-weight: 600;
    }
  }
  &__cards {
    background-size: cover;
    padding: toRem(20);
    height: toRem(340);
    margin: toRem(20);
    color: white;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    row-gap: 15px;
  }

  .card-button {
    border: toRem(2) solid #84b22a;
    border-radius: 25px;
    padding: toRem(5) toRem(10);
    cursor: pointer;
    font-weight: 600;
    &:hover {
      background-color: rgba(255, 255, 255, 0.6);
      color: white;
    }
  }

  .card-title,
  .card-button {
    @include responsive(tab-port) {
      font-size: toRem(22);
    }
  }
}

#article1 {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 100)
    ),
    url("/assets/img/home/article_1.jpg");
  width: toRem(300);
}

#article2 {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 100)
    ),
    url("/assets/img/home/article_2.jpg");
  width: toRem(240);
  @include responsive(tab-port) {
    width: toRem(300);
  }
}

#article3 {
  background-size: cover;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 100)
    ),
    url("/assets/img/home/article_3.jpg");
  width: toRem(240);
  @include responsive(tab-port) {
    width: toRem(300);
  }
}
