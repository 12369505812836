$headerColor: #373f41;
$headerBackgroundColor: rgba(255, 255, 255, 0.7);

.header {
  background-color: $headerBackgroundColor;
  // position: absolute;
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  font-size: toRem(14);

  nav {
    padding: toRem(20) toRem(80);

    @include responsive(tab-port) {
      padding: toRem(20) toRem(40);
    }

    a:focus {
      outline: none;
    }
  }

  .overlay {
    position: fixed;
    width: 100%;
    opacity: 0;
    z-index: -1;
    // display: none;
    height: 100%;
    // background-color: $headerColor;
    // background-color: white;
  }

  // hamburger open styles
  &.open {
    .overlay {
      display: block;
    }

    .header__menu {
      z-index: 1;
    }
    .header__toggle {
      &.fade-in {
        > span {
          // background-color: white;
          background-color: #373f41;
        }
      }

      > span:first-child {
        transform: rotate(30deg);
      }

      > span:nth-child(2) {
        opacity: 0;
      }

      > span:last-child {
        transform: rotate(-30deg);
      }
    }
  }

  &__logo {
    img {
      max-width: 120px;
    }
  }

  &__language {
    display: inline-flex;
    margin-left: toRem(40);
    & input[type="radio"] {
      display: none;
    }

    & label {
      display: inline-block;
      padding: 4px 11px;
      font-size: toRem(12);
      cursor: pointer;
    }

    & input[type="radio"]:checked + label {
      color: $darkBlue;
    }
  }

  &__toggle {
    > span {
      display: block;
      width: 26px;
      height: 2px;
      background-color: $darkGray;
      transition: all 300ms ea -in-out;
      -ms-transition: all 300ms ease-in-out;
      -webkit-transition: all 300ms ease-in-out;
      transform-origin: -1px 1px;

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  &__menu {
    background-color: white; //$headerBackgroundColor;
    // padding: toRem(26);
    padding: 0 toRem(26) toRem(26) toRem(26);
    // margin: 0 toRem(24) 0 toRem(24);
    // width: calc(100% - 3rem);
    // margin: 0 toRem(24) 0 toRem(24);
    margin: 0;
    width: 100%;
    position: absolute;

    a {
      color: $headerColor;
      display: block;
      text-align: center;
      padding: toRem(20);
      font-size: toRem(18);
    }

    a:focus,
    a:hover {
      color: $highLightBlue;
    }
  }
  &__links {
    a {
      color: $headerColor;
      transition: all 0.6s;
      border-bottom: 0px;
      &:not(:last-child) {
        margin-right: toRem(50);
      }

      &:hover {
        padding-bottom: toRem(5);
        color: $highLightBlue;
        border-bottom: 2px solid $highLightBlue;
      }
    }

    .active {
      color: $highLightBlue;
    }
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
  }
}
