.modal-dialog {
  // @include
  // @include responsive(tab-port) {
  //   width: 35px;
  // }

  // @include responsive(phone) {

  // }
  // width: 1000px;
  // @include responsive(tab-land) {
  //   width: 910px;
  // }
  @include breakpoint-up(large) {
    width: 920px;
  }
  @include responsive(big-desktop) {
    width: 1200px;
  }
}

.product {
  background-color: white;
  border-radius: 25px;
  padding: toRem(20) toRem(40) toRem(40) toRem(40);
  max-width: 1200px;

  &__name {
    margin-top: 0px;
    font-weight: 600;
  }

  &__product-id {
    color: rgb(1, 131, 197);
    font-weight: 600;
  }
  &__description_title {
    padding-bottom: toRem(10);
    border-bottom: 1px solid black;
  }
}
$big-font: toRem(32);
$big-card: 196px;
$big-product-font: toRem(16);

$small-font: toRem(24);
$small-card: 145px;
$small-product-font: toRem(14);

.card {
  color: white;
  width: $big-card;
  margin: toRem(16);
  cursor: pointer;

  @include responsive(tab-port) {
    width: $small-card;
  }

  &__halal {
    width: 45px;
    z-index: 10;
    margin-top: 20px;
    margin-left: -20px;
    position: absolute;
    filter: drop-shadow(0.2px 0.2px 2px #6e747c);

    @include responsive(tab-port) {
      width: 35px;
    }
  }

  &__id {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1;
    font-size: $big-font;
    width: 90%;

    @include responsive(tab-port) {
      width: $small-card;
      font-size: $small-font;
    }
  }

  &__image {
    display: inline-block;
    position: relative;
    // border-radius: 25px;
    // box-shadow: 8px 9px 10px -6px #0000001c;

    img {
      width: $big-card;
      max-width: unset;

      @include responsive(tab-port) {
        width: $small-card;
      }
    }

    &::after {
      content: "";
      display: inline-block;
      background-color: rgba(117, 124, 131, 0.9);
      height: $big-card;
      width: $big-card;
      border-radius: 25px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @include responsive(tab-port) {
        width: $small-card;
        height: $small-card;
        border-radius: 18px;
      }
    }
  }
  &__name {
    color: $gray;
    text-align: center;
    font-size: $big-product-font;

    @include responsive(tab-port) {
      font-size: $small-product-font;
    }
  }
}
