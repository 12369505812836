@mixin about_title($color: #686868) {
  color: $color;
  font-weight: 600;
  border-radius: 808px;
  border: 2px solid $color;
  padding: 5px 10px;

  @include responsive(tab-port) {
    font-size: toRem(28);
  }
}

.section-container {
  padding: toRem(40);
  margin-left: toRem(50);
  margin-right: toRem(50);

  @include responsive(tab-port) {
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }
}

.hero-factory.row {
  margin-right: 0px;
  margin-left: 0px;
}

.hero-factory {
  &__p {
    font-size: 26px;
    position: absolute;
    bottom: 90px;
    font-weight: 400;
    width: 100%;
    padding: 40px;
    text-align: center;
    display: flex;
    justify-content: center;

    p {
      width: 80%;
    }
  }
}

.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 6rem;
    padding-bottom: 6rem;

    @include responsive(tab-port) {
      flex-direction: column;
      text-align: center;
      padding: toRem(40);
      img {
        width: 80%;
      }
    }
  }

  &__left {
    img {
      height: toRem(420);
    }

    @include responsive(tab-port) {
      img {
        height: auto;
        width: 100%;
      }
    }
  }

  &__right {
    width: 50%;
    text-align: left;
    line-height: 2rem;

    .contents {
      p {
        line-height: 2rem;
      }
      .bold {
        font-weight: 700;
      }
    }

    @include responsive(tab-port) {
      width: 100%;
      margin-top: toRem(20);
      text-align: center;
    }
  }
}

.process {
  &__container {
    margin: toRem(60);
    p {
      text-align: center;
    }
  }
  &__title {
    font-size: toRem(20);
  }

  &__bubbles {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: toRem(40) toRem(30);
    row-gap: 10px;
    font-size: toRem(18);
    font-weight: 700;

    @include responsive(tab-port) {
      margin: 0;
    }
  }

  &__bubble-svg {
    flex-basis: 27%;
    text-align: center;
    background: url("/assets/logo/bubble.svg");
    color: #313149;
    padding: 30px;
    border-radius: 15px;
    display: inline-block;

    @include responsive(tab-port) {
      flex-basis: 100%;
    }
  }
}

.value {
  background-image: url("/assets/img/about/about_us_togetherness.png");
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  padding-bottom: toRem(40);

  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    // display: flex;
    // align-items: center;
    margin-top: toRem(20);
    padding-top: toRem(30);

    h1 {
      @include about_title(white);
    }

    // @include responsive(tab-port) {
    //   flex-direction: column;
    // }
  }

  &__introduction {
    text-align: center;
    width: 75%;
    // margin: 0 5rem 4rem 5rem;
    margin-bottom: 2rem;
    padding-bottom: 3rem;
  }

  &__cards {
    padding: toRem(20);
    flex-basis: 25%;
    line-height: toRem(22);

    h4 {
      font-weight: 600;
      margin-bottom: toRem(20);
    }

    @include responsive(tab-port) {
      flex-basis: 90%;
    }
  }

  &__contents {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    padding-bottom: toRem(40);

    @include responsive(tab-port) {
      padding: 0;
    }

    #commit {
      border-bottom: 2px solid white;
      border-right: 2px solid white;

      @include responsive(tab-port) {
        border: 0;
      }
    }

    #optimistic {
      border-bottom: 2px solid white;
      border-right: 2px solid white;
      @include responsive(tab-port) {
        border: 0;
      }
    }

    #objective {
      border-bottom: 2px solid white;
      @include responsive(tab-port) {
        border: 0;
      }
    }

    #innovative {
      border-left: 2px solid white;
      flex-basis: 37%;

      @include responsive(tab-port) {
        flex-basis: 90%;
        border: 0;
      }
    }

    #responsible {
      flex-basis: 37%;
      @include responsive(tab-port) {
        flex-basis: 90%;
      }
    }
  }
}

.approach {
  background-image: url("/assets/img/about/about_us_background_dots.png");
  width: 100%;
  padding-bottom: 5rem;

  .rows {
    display: flex;
    padding-top: 2.25rem;

    @include responsive(tab-port) {
      flex-direction: column;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    margin-top: toRem(20);
    h1 {
      @include about_title();
    }

    @include responsive(tab-port) {
      flex-direction: column;
    }
  }

  &__left {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__right {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 90%;
    text-align: center;
    align-items: center;

    h3 {
      font-style: italic;
      color: #298f74;
      font-weight: 600;
      font-size: toRem(48);
      line-height: toRem(40);
      letter-spacing: 0.2px;

      &:nth-child(2) {
        margin-left: toRem(70);
      }

      @include responsive(tab-port) {
        font-size: toRem(36);
      }
    }
    p {
      width: toRem(400);
      max-width: 100%;
      line-height: toRem(26);
    }
  }

  &__image {
    img {
      max-height: toRem(320);

      @include responsive(tab-port) {
        height: auto;
        width: 90%;
        display: flex;
        margin: 0 auto;
      }
    }
  }

  #relationship {
    .approach {
      &__left {
        @include responsive(tab-port) {
          order: 1;
        }
      }
    }
  }
}
