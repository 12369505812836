.footer {
  // position: absolute;
  bottom: 0;
  color: white;
  // background-color: $darkBlue;
  background: linear-gradient(90deg, #123468 0%, #174868 100%);
  left: 0;
  right: 0;
  min-height: toRem(350);
  font-weight: 700;
  line-height: toRem(24);

  @include responsive(tab-land) {
    min-height: toRem(440);
  }

  &__logo {
    @include breakpoint-down(small) {
      height: 100px;
    }
  }

  &__left {
    padding-left: toRem(120);
    padding-top: toRem(60);
    padding-bottom: toRem(10);
    // change padding on small device
    @include breakpoint-down(medium) {
      padding-left: toRem(60);
    }

    // change padding on small device
    @include breakpoint-down(small) {
      padding-left: toRem(15);
    }
  }

  &__right {
    padding-right: toRem(120);
    padding-top: toRem(90);
    padding-bottom: toRem(60);

    // change padding on medium device
    @include breakpoint-down(medium) {
      padding-left: toRem(60);
      padding-top: toRem(90);
    }

    // change padding on small device
    @include breakpoint-down(small) {
      padding-left: toRem(15);
    }

    @include breakpoint-up(large) {
      padding-top: toRem(75);
    }
  }

  .address {
    width: toRem(430);
    margin-left: toRem(30);
  }

  .contact {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;

    @include breakpoint-down(medium) {
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }

    &__logo {
      margin: auto toRem(30);
      // margin-right: toRem(30);
    }

    &__phone {
      min-width: 137px;
    }
  }
}
