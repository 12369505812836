@import "style/variables";
@import "style/mixins";
@import "style/animation";
@import "style/globals";

@import "components/product/product";
@import "components/header/navbar";
@import "components/footer/footer";
@import "components/page/ourproduct";
@import "components/page/home";
@import "components/page/contact";
@import "components/page/about";
@import "components/page/halal";
@import "components/utils/hexagon";

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 350ms ease-in;
}
