//  Color
$darkBlue: #223849;
$highLightBlue: #448ab0;
$lightBlue: #0183c5;

$lightGray: #ebebeb;
$black: #070707;
$darkGray: #332c2b;
$green: #6f950f;
$gray: #686868;

// Font Weights
$thin: 100;
$light: 300;
$regular: 400;
$semibold: 600;
$bold: 700;
$ultra: 800;

@function toRem($n) {
  @return ($n / 16) + rem;
}

@function toEm($n) {
  @return ($n / 16) + em;
}

.gray {
  color: #686868;
}

.font {
  &__300 {
    font-weight: 300;
  }
  &__400 {
    font-weight: 400;
  }

  &__500 {
    font-weight: 500;
  }

  &__600 {
    font-weight: 600;
  }

  &__700 {
    font-weight: 700;
  }
}
.mar {
  // top
  &-t {
    &-5 {
      margin-top: toRem(5);
    }

    &-10 {
      margin-top: toRem(10);
    }

    &-15 {
      margin-top: toRem(15);
    }

    &-20 {
      margin-top: toRem(20);
    }
  }

  // bottom
  &-b {
    &-5 {
      margin-bottom: toRem(5);
    }

    &-10 {
      margin-bottom: toRem(10);
    }

    &-15 {
      margin-bottom: toRem(15);
    }

    &-20 {
      margin-bottom: toRem(20);
    }
  }
}

.pad {
  // left
  &-l {
    &-5 {
      padding-left: toRem(5);
    }

    &-10 {
      padding-left: toRem(10);
    }

    &-15 {
      padding-left: toRem(15);
    }

    &-20 {
      padding-left: toRem(20);
    }

    &-40 {
      padding-left: toRem(40);
    }

    &-60 {
      padding-left: toRem(60);
    }

    &-80 {
      padding-left: toRem(80);
    }
  }

  // right
  &-r {
    &-5 {
      padding-right: toRem(5);
    }

    &-10 {
      padding-right: toRem(10);
    }

    &-15 {
      padding-right: toRem(15);
    }

    &-20 {
      padding-right: toRem(20);
    }

    &-40 {
      padding-right: toRem(40);
    }

    &-60 {
      padding-right: toRem(60);
    }

    &-80 {
      padding-right: toRem(80);
    }
  }

  // top
  &-t {
    &-5 {
      padding-top: toRem(5);
    }

    &-10 {
      padding-top: toRem(10);
    }

    &-15 {
      padding-top: toRem(15);
    }

    &-20 {
      padding-top: toRem(20);
    }

    &-40 {
      padding-top: toRem(40);
    }

    &-60 {
      padding-top: toRem(60);
    }

    &-80 {
      padding-top: toRem(80);
    }
  }

  // bottom
  &-b {
    &-5 {
      padding-bottom: toRem(5);
    }

    &-10 {
      padding-bottom: toRem(10);
    }

    &-15 {
      padding-bottom: toRem(15);
    }

    &-20 {
      padding-bottom: toRem(20);
    }
  }
}
