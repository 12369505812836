.halal {
  // background-color: #f3f3f3;
  color: #7b7b7b;

  h1 {
    color: inherit;
    border-color: #acaf44;
    display: inline-block;
  }

  p {
    text-align: justify;
    font-weight: 600;
    line-height: toRem(28);
    padding-top: toRem(15);
  }

  &__title {
    padding-left: 1rem;
  }

  .left,
  .right {
    flex-basis: 45%;
  }

  .right {
    p:first-child {
      padding-top: 0;
    }
  }

  .left {
    p {
      padding-top: toRem(25);
    }
  }

  &__contents {
    padding: toRem(15) toRem(15) toRem(35) toRem(15);
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include responsive(tab-port) {
      flex-direction: column;
    }

    @include responsive(tab-port) {
      font-size: toRem(18);
    }
  }

  &__cards {
    border: 2px solid #7b7b7b;
    margin: toRem(15) 0;
    border-radius: 25px;
    // width: 100%;
    cursor: pointer;
    padding: toRem(5) toRem(10);
    display: flex;
    justify-content: space-between;
    align-items: center;

    flex-basis: 45%;
    p {
      padding: 0;
      margin: 0;
    }

    &:hover {
      background-color: white;
      -webkit-animation: pulse 0.5s;
      animation: pulse 0.5s;
      animation-iteration-count: 1;
      .product-id {
        color: black;
      }
    }

    .product-id {
      color: #7b7b7b;

      &::before {
        background-color: #23527c;
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
      }
    }

    .product-cat {
      color: #686868;
      font-weight: 700;
    }

    .product-subcat {
      color: #757c83;
      font-weight: 500;
    }
  }
}

.product-contents {
  padding-bottom: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
