// USING REM: using 3 system device

// >=640px, >=1024px, >=1400px
$breakpoints-up: (
  "medium": toRem(601),
  "large": toRem(1024),
  "xlarge": toRem(1400),
);

// < 640px, < 1024px, < 1400px
$breakpoints-down: (
  "small": toRem(600),
  "medium": toRem(1023),
  "large": toRem(1399),
);

@mixin breakpoint-up($size) {
  @media (min-width: map-get($breakpoints-up, $size)) {
    @content;
  }
}

@mixin breakpoint-down($size) {
  @media (max-width: map-get($breakpoints-down, $size)) {
    @content;
  }
}

// USING EM: using 4 system device
@mixin responsive($breakpoint) {
  // 37.5em or 600px
  @if $breakpoint == phone {
    @media (max-width: toEm(600)) {
      @content;
    }
  }

  // 57.25em or 900px
  @if $breakpoint == tab-port {
    @media (max-width: toEm(900)) {
      @content;
    }
  }

  // 75em or 1200px
  @if $breakpoint == tab-land {
    @media (max-width: toEm(1200)) {
      @content;
    }
  }

  // 112.5em or 1800px
  @if $breakpoint == big-desktop {
    @media (min-width: toEm(1600)) {
      @content;
    }
  }
}
