.contact {
  &__container {
    display: flex;
    flex-direction: row;
    margin-bottom: toRem(100);

    @include responsive(phone) {
      flex-direction: column;
    }
  }

  &__left {
    flex-basis: 20%;
    font-size: toRem(20);
    border-radius: toRem(250);
    background: rgb(36, 127, 63);
    background: linear-gradient(
      0deg,
      rgba(36, 127, 63, 1) 0%,
      rgba(16, 98, 63, 1) 100%
    );
    color: white;
    margin-top: toRem(-100);
    margin-left: toRem(-50);

    @include responsive(phone) {
      margin-left: toRem(30);
      margin-right: toRem(30);
    }
  }

  &__left-container {
    margin: toRem(100);
    margin-top: toRem(150);
    margin-left: toRem(120);

    @include responsive(tab-port) {
      margin-left: toRem(70);
    }

    @include responsive(big-desktop) {
      margin-left: toRem(100);
      margin-right: toRem(80);
    }

    .section {
      margin-bottom: toRem(35);
    }

    .social-medias {
      display: flex;
      justify-content: flex-start;

      & > * {
        margin-top: toRem(10);
        margin-right: toRem(10);
      }
    }
  }

  &__title {
    text-transform: uppercase;
    font-weight: 700;

    .icon {
      margin-right: 10px;
    }
  }

  &__right {
    margin: toRem(40) toRem(50);
    flex-basis: 64%;

    p {
      font-size: toRem(20);
    }

    @include responsive(tab-port) {
      p {
        text-align: center;
      }
    }

    &__google-maps {
    }
  }

  &__forms {
    display: flex;
    flex-direction: column;
    width: 100%;

    #btn-form-submit {
      align-self: flex-end;
      margin: 15px;
      margin-right: 26px;
      width: toRem(120);
      border: 2px solid #726d6d;
    }
  }
}

.mapouter {
  position: relative;
  text-align: right;
  height: 400px;
  // width: 500px;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 400px;
  // width: 500px;
}
