@keyframes fade-in {
  from {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }

  1% {
    opacity: 0;
    visibility: visible;
    z-index: 1;
  }
  to {
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }
}

.fade-in {
  animation: fadein 300ms ease-in-out forwards;
  -webkit-animation: fadein 300ms ease-in-out forwards;
  // visibility: visible;
  // opacity: 1;
  // transition: visibility 0s linear 0s, opacity 300ms;
}

@keyframes fade-out {
  from {
    opacity: 100%;
    visibility: visible;
    z-index: 1;
  }
  99% {
    visibility: visible;
    opacity: 0;
    z-index: -1;
  }
  to {
    opacity: 0;
    visibility: visible;
    z-index: -1;
  }
}

// .fade-out {
//   animation: fade-out 300ms ease-in-out forwards;
//   -webkit-animation: fade-out 300ms ease-in-out forwards;
// }
.fade-out {
  opacity: 0;
  visibility: visible;
  z-index: -1;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-60px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.grow-down {
  animation: growDown 300ms ease-in-out forwards;
  -webkit-animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
}

@for $i from 0 through 5 {
  .submenu__animation-#{$i} {
    transform-origin: top center;
    animation: slideDown 300ms ($i * 70ms) ease-in-out forwards;
    -webkit-animation: slideDown 300ms ($i * 70ms) ease-in-out forwards;
  }
}
