html {
  font-size: 100%; // 100% equals to 16px

  @include responsive(big-desktop) {
    font-size: 150%;
  }

  @include responsive(tab-port) {
    font-size: 125%;
  }

  @include responsive(phone) {
    font-size: 104%;
  }
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

// making sure that the image will resize as the parent resize
img {
  max-width: 100%;
}

body {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: toRem(14);
  box-sizing: border-box;
  min-height: 100vh;
}

ul {
  list-style: none;
}

a,
a:visited,
a:hover {
  text-decoration: none;
}

.container-fluid {
  padding: 0 !important;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex-dir-col {
  flex-direction: column;
}

.flex-dir-row {
  flex-direction: row;
}

.flex-jc-sb {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flex-jc-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.flex-jc-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.flex-basis-50 {
  flex-basis: 50%;
}

.flex-jc-c {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.flex-ai-c {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.hide-for-mobile {
  @include breakpoint-down(medium) {
    display: none;
  }
}

.hide-for-desktop {
  @include breakpoint-up(large) {
    display: none;
  }
}

.row.equal-cols {
  @include breakpoint-up(large) {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    &:before,
    &:after {
      display: block;
    }

    & > [class*="col-"] {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    & > [class*="col-"] > * {
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
    }
  }
}

.section-divider {
  height: 1px;
  width: calc(100% - 15rem);
  background-color: $lightGray;
  margin: 0 auto;
}

.hero-factory {
  display: none;

  @include responsive(tab-port) {
    display: block;
    z-index: -5;
    position: relative;
  }

  @include responsive(phone) {
    display: block;
    z-index: -5;
    position: relative;
    // img {
    //   margin-top: -90px;
    // }
  }

  @media screen and (min-device-width: 901px) and (max-device-width: 1023px) {
    display: block;
    z-index: -5;
    position: relative;
  }

  @include breakpoint-up(large) {
    display: block;
    z-index: -5;
    position: relative;
    font-size: toRem(54);
    font-weight: 900;
    color: white;
    img {
      // min-width: 100%;
      // padding: 0;
      margin-top: -50px;
    }

    &__title {
      .upper {
        position: absolute;
        top: toRem(200);
        left: toRem(240);

        @include responsive(big-desktop) {
          top: toRem(170);
          left: toRem(190);
        }
      }

      .lower {
        position: absolute;
        top: toRem(260);
        left: toRem(310);

        @include responsive(big-desktop) {
          top: toRem(230);
          left: toRem(270);
        }
      }
    }
  }
}

.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 6rem;
    padding-bottom: 6rem;

    @include responsive(tab-port) {
      flex-direction: column;
      text-align: center;
      padding: toRem(40);
      img {
        width: 80%;
      }
    }
  }

  &__left {
    img {
      height: toRem(420);
    }

    @include responsive(tab-port) {
      img {
        height: auto;
        width: 100%;
      }
    }
  }

  &__right {
    width: 50%;
    text-align: left;
    line-height: 2rem;

    .contents {
      p {
        line-height: 2rem;
      }
      .bold {
        font-weight: 700;
      }
    }

    @include responsive(tab-port) {
      width: 100%;
      margin-top: toRem(20);
      text-align: center;
    }
  }
}

.inputarea-form {
  display: block;
  border-radius: 25px;
  border: 2px solid #726d6d;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  width: 97.5%;
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 15px;
  transition: all 1s;
  &:focus {
    width: 100%;
    border: 2px solid #000;
  }
}

.input-form {
  display: block;
  border-radius: 25px;
  border: 2px solid #726d6d;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  font-size: 18px;
  width: 97.5%;
  margin-top: 15px;
  margin-bottom: 15px;
  transition: all 1s;
  &:focus {
    width: 100%;
    border: 2px solid #000;
  }
}

.quotation {
  margin: 0 auto;
  padding: toRem(40) toRem(120);
  // padding-bottom: toRem(40);

  @include responsive(tab-port) {
    padding: toRem(40) toRem(40);
  }

  &__forms {
    display: flex;
    flex-direction: column;

    #btn-quotation-form-submit {
      width: 7.5rem;
      align-self: flex-end;
    }
  }
}
