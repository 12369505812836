// .product-container {
//   padding: toRem(20);
// }

.cat-open {
  opacity: 1;
  // display: block;
  // visibility: visible;
  // height: auto;
}

.cat-close {
  // transition: height 0.8s ease-in-out;
  // visibility: hidden;
  // height: 0;
  display: none;
  // opacity: 0;
}

.sidebar {
  background-color: $darkBlue;
  color: white;
  cursor: pointer;
  font-weight: $semibold;

  @include breakpoint-up(large) {
    min-height: toRem(1250);
  }

  &__header {
    text-align: center;
    padding: toRem(10) toRem(10) toRem(20) toRem(10);
    h2 {
      font-size: toRem(24);
    }

    @include breakpoint-up(large) {
      text-align: left;
      padding: toRem(60) toRem(0) toRem(20) toRem(0);
      margin-left: 60px;
      margin-right: 60px;
      h2 {
        font-size: toRem(30);
        font-weight: 500;
      }
    }
  }

  &__content {
    padding-bottom: 20px;
  }

  &__divider {
    margin: 30px auto;
    margin-top: 40px;
    width: 100%;
    height: 3px;
    background-color: white;
  }
}

.menu {
  background-color: $darkBlue;
  color: white;
  border: none;
  margin-bottom: toRem(5);

  ul {
    margin: 0;
  }

  span {
    font-size: toRem(20);
  }

  @include breakpoint-up(large) {
    text-align: left;
    margin-left: 60px;
    margin-right: 60px;
  }

  .sidebar_dropdown {
    // transition: height 0.5s ease-in-out;
    // visibility: hidden;
    // height: 0;
    // opacity: 0 ;

    .submenu {
      font-size: toRem(16);
      padding-top: toRem(7);
      padding-bottom: toRem(7);
      perspective: 1000px;
      z-index: -1;

      &:first-child {
        padding-top: toRem(10);
      }

      a {
        color: white;
      }

      .subcategory {
        border: 2px solid transparent;
        border-radius: 25px;
        padding: 5px 10px;
        margin-left: -5px;
        margin-top: -10px;

        &:active,
        &:focus,
        &:hover {
          color: $highLightBlue;
          border: 2px solid $highLightBlue;
        }
      }

      @include breakpoint-up(large) {
        padding-top: toRem(20);
        margin-left: 10px;

        &:first-child {
          padding-top: toRem(20);
        }
      }
    }
  }

  &:last-child {
    .sidebar__divider {
      display: none;
    }
  }
}

$small-padding: toRem(20);
$large-padding: toRem(35);

.contents {
  // background-color: white;
  padding-left: $large-padding;
  padding-right: $large-padding;

  @include responsive(phone) {
    padding-left: $small-padding;
    padding-right: $small-padding;
  }

  & h3 {
    font-weight: 700;
    font-size: toRem(21);

    @include responsive(phone) {
      text-align: center;
    }
  }

  & h1 {
    font-weight: 700;
    font-size: toRem(42);
    padding-bottom: toRem(10);

    @include responsive(phone) {
      text-align: center;
      font-size: toRem(36);
    }
  }

  & p {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: toRem(16);
    padding-top: toRem(15);
    line-height: toRem(25);
    letter-spacing: 0.5px;

    @include responsive(phone) {
      text-align: center;
    }
  }
}

// .sidebar {
//   background-color: $darkBlue;
//   color: white;
//   height: 100%;
//   padding-left: toRem(45);
//   padding-right: toRem(45);
//   // padding-bottom: toRem(45);
//   // &__content {
//   //   padding-left: toRem(45);
//   //   padding-right: toRem(45);
//   // }

//   &__categories {
//     font-weight: 700;
//     line-height: 18px;
//     font-style: normal;
//     font-size: toRem(25);
//   }

//   &__subcategories {
//     font-size: toRem(18);
//     font-weight: bold;
//     & li {
//       margin-left: 15px;
//       margin-top: toRem(25);
//     }
//   }

// &__divider {
//   margin-top: 65px;
//   margin-bottom: 50px;

//   border: 1px solid #ffffff;
// }
// }
